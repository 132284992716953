import { ResponsiveSankey } from '@nivo/sankey'

const SankeyChart = (props) => (
    <ResponsiveSankey
        data={props.data}
        margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
        layout="vertical"
        align="justify"
        sort="input"
        colors={{ scheme: 'nivo' }}
        // colors={{ datum: 'data.nodeColor' }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={110}
        nodeBorderWidth={0}
        nodeBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.8
                ]
            ]
        }}
        nodeBorderRadius={3}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="outside"
        labelPadding={16}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1
                ]
            ]
        }}

    />
)
export default SankeyChart
