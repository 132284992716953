import React, { useState, useEffect, useRef } from "react";
import FilterBar from "../FilterBar/FillterBar"
import TabulacaoHeader from "../Tabulacoes/TabulacaoHeader";
import TabulacaoContainer from "../Tabulacoes/TabulacaoContainer";
import '../Tabulacoes/Tabulacoes.css'



const Tabulacoes = props => {

    const { currState, setCurrState } = props
    const { tabulacoesData, DataInicio, DataFim, filterOptions } = currState
    const firstRun = useRef()
    console.log(currState)

    useEffect(() => {
        if (!firstRun.done && !tabulacoesData) fetchTabulacoes()
        firstRun.done = true
    }, []);


    const fetchTabulacoes = async () => {
        return new Promise(async (resolve) => {
            try {
                let filterData = filterOptions ? filterOptions : await fetchfilterOptions()
                let res = await fetch(`/api/tabulacoes/from=${new Date(DataInicio).toISOString()}/to=${new Date(DataFim).toISOString()}/adm_id=${currState.clienteID}/func_id=${currState.funcID}`)
                let data = await res.json()
                setCurrState({ ...currState, tabulacoesData: { ...data.db }, filterOptions: { ...filterData } })
            } catch (e) {
                window.alert(e)
            }
            resolve()
        })

    }
    const fetchfilterOptions = () => {
        return new Promise(resolve => {
            try {
                fetch('/api/get/filterOptions')
                    .then(res => res.json())
                    .then(data => resolve(data.db))
            } catch (e) {
                window.alert(e)
            }
        })

    }


    return (
        <>
            <FilterBar currState={currState} setCurrState={setCurrState}
                update={() => fetchTabulacoes()} clientes funcionarios />
            {tabulacoesData &&
                <>
                    <TabulacaoHeader currState={currState} setCurrState={setCurrState} tabulacoesData={tabulacoesData} />
                    <TabulacaoContainer tabulacoesData={tabulacoesData} />
                </>
            }

        </>
    )
}

export default Tabulacoes