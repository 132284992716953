import React from "react";
import { Link } from 'react-router-dom';
import './Aside.css'
import logo from '../../assets/logo.webp'
import AsideLogo from "./AsideLogo";
import AsideItem from "./AsideItem";

import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faShopify } from '@fortawesome/free-brands-svg-icons/faShopify'

const Aside = props => (
    <aside className="aside">
        <AsideLogo src={logo} />
        <Link to="/"><AsideItem to="/" title="Relatórios" icon={faChartLine} /></Link>
        <Link to="/tabulacoes"><AsideItem title="Tabulacoes" icon={faChartPie} /></Link>
        <Link to="/shopify"><AsideItem title="Shopify" icon={faShopify} /></Link>
        <Link to="/funcionarios"><AsideItem title="Funcionários" icon={faUsers} /></Link>
        <Link to="/clientes"><AsideItem title="Clientes" icon={faUserEdit} /></Link>
        {/* <Link to="/whatsapp"><AsideItem title="WhatsApp" icon={faWhatsapp} /></Link> */}
    </aside>
)

export default Aside