import React from "react";
import PieChartWithLabels from "../Charts/PieChartWithLabels";
import HorizontalBarChart from "../Charts/HorizontalBarChart";

const TabulacaoContainer = props => {
    const { tabulacoesData } = props
    const activeTag = tabulacoesData.active_tag ? tabulacoesData.active_tag : 'conversao'

    const tagNameByValue = {
        'conversao': 'Conversão',
        'conversao_perdida': 'Conversão Perdida',
        'suporte_pos_venda': 'Suporte Pós Venda'
    }
    return (

        <div className="tabulacao-container">
            <div className="tabulacao-tags wct">
                <div className="data-title">Tags da Tabulação - {tagNameByValue[activeTag]}</div>
                {tabulacoesData[activeTag] && tabulacoesData[activeTag].tags &&
                    <div className="tabulacao-tags-graph">
                        <PieChartWithLabels data={tabulacoesData[activeTag].tags} />
                    </div>
                }
            </div>
            <div className="tabulacao-tempos wct">
                <div className="data-title">Tempo Médio de Atendimento (minutos)</div>
                {tabulacoesData[activeTag] && tabulacoesData[activeTag].tempos &&
                    <div className="tabulacao-tags-tempos">
                        <HorizontalBarChart data={tabulacoesData[activeTag].tempos} />
                    </div>
                }
            </div>
        </div>
    )
}
export default TabulacaoContainer