import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'

const DataContainer = props => (
    <div className="data-container wct" onClick={props.onClick}>
        <div className="data-title">{props.title}</div>
        <div className="data-info">
            <div className="data-info-left">
                <h1 className="main-data">{props.bigvalue}</h1>
                <p className="obs">{props.obs}</p>
            </div>
            <div className="data-info-right">
                <div className="small-graph fc">{props.children}</div>
            </div>
        </div>
        {props.tempo_atendimento && props.tempo_prim_resp &&
            <div className="extended-info">
                <div>Tempo Médio</div>
                <div style={{ marginTop: '5px' }}>
                    <FontAwesomeIcon icon={faClock} style={{ color: "#858585", }} />
                    {props.tempo_atendimento}
                </div>
                <div style={{ marginTop: '10px' }}>Primeira Resposta</div>
                <div style={{ marginTop: '5px' }}>
                    <FontAwesomeIcon icon={faClock} style={{ color: "#858585", }} />
                    {props.tempo_prim_resp}
                </div>
            </div>
        }
    </div>
)

export default DataContainer