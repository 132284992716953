import React from "react"
import './Beeloader.css'

const Beeloader = () => (
    <div className="bee-container">
        <div className="bee">
            <div className="bee-body">
                <div className="tail"></div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="wing-right"></div>
                <div className="wing-left"></div>
            </div>
        </div>
    </div>
)

export default Beeloader
