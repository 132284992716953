import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
const LeftControls = props => {
    //pros.update -> Reload function
    //props.funcionarios -> Add filtro funcionario
    //props.clientes -> Add fitro cliente

    const { currState, setCurrState } = props
    const { clienteID, funcID, DataInicio, DataFim, filterOptions } = currState

    const renderOptions = (type, opList) => {
        const selectedID = type == 'clientes' ? clienteID : funcID
        return (
            opList.map((op) =>
                <option key={op.id} id={op.id} value={op.id}
                    selected={selectedID == op.id}
                >{op.nome} </option>
            ))
    }

    return (
        <div className="filter-top-bar">
            <div style={{ display: 'flex', gap: '10px' }}>
                <FontAwesomeIcon icon={faFilter} color='gray' size='lg' />
                <p>Filtros</p>
            </div>
            {filterOptions && props.clientes &&
                <div className="clientefilter">
                    <span>Cliente: </span>
                    <select name="Cliente" id="clienteSelect"
                        onChange={(e) => setCurrState({ ...currState, clienteID: e.target.value })}>
                        <option value="0" selected={clienteID == '0'}>Todos</option>
                        {renderOptions('clientes', filterOptions.admList)}
                    </select>
                </div>
            }
            {filterOptions && props.funcionarios &&
                <div className="funcfilter">
                    <span>Funcionário: </span>
                    <select name="Funcionários" id="func"
                        onChange={(e) => setCurrState({ ...currState, funcID: e.target.value })}>
                        <option value="0">Todos</option>
                        {renderOptions('funcionarios', filterOptions.funcList)}
                    </select>
                </div>
            }

            <div className="datefilter">
                <span style={{ marginRight: '5px' }}>Início: </span>
                <DatePicker locale={ptBR} selected={DataInicio}
                    onChange={(date) => setCurrState({ ...currState, DataInicio: date })}
                    dateFormat='dd/MM/yyyy' />

                <span style={{ margin: '0px 5px' }}>Fim: </span>
                <DatePicker locale={ptBR} selected={DataFim}
                    onChange={(date) => setCurrState({ ...currState, DataFim: date })}
                    dateFormat='dd/MM/yyyy' />

            </div>
            <button className="export-action" onClick={props.update}>Gerar Relatório</button>
        </div>
    )
}
export default LeftControls