import React from "react";
import Beeloader from "../BeeLoader/Beeloader";

const Main = (props) => (
  <main style={{ flexDirection: "column" }}>
    <div className="section-header wct">
      <h1>{props.title}</h1>
    </div>
    {(props.currState.beeLoading
      || (props.title == 'Shopify' && !props.currState.shopifyData)
      || (props.title == 'Tabulações' && !props.currState.tabulacoesData)
      || (props.title == 'Funcionários' && !props.currState.funcionarios)
      || (props.title == 'Funcionários' && !props.currState.funcionarios)
      || (props.title == 'Clientes' && !props.currState.clientes)) && <Beeloader />}
    {props.children}
  </main>
);

export default Main