import React from "react";
import DataContainer from "../Dashboard/DataContainer";
import SmallContainerPieChart from "../Charts/SmallContainerPieChart";

const TabulacaoHeader = props => {
    const { currState, setCurrState, tabulacoesData } = props

    return (
        <div className="header-container tabulacao-header">
            <DataContainer title="Conversão" bigvalue={tabulacoesData.conversao.big_value} obs="Do total de atendimentos"
                tempo_atendimento={tabulacoesData.conversao.tempo_atendimento}
                tempo_prim_resp={tabulacoesData.conversao.tempo_prim_resp}
                onClick={() => setCurrState({ ...currState, tabulacoesData: { ...tabulacoesData, active_tag: 'conversao' } })}
            >{tabulacoesData.conversao.category &&
                <SmallContainerPieChart data={tabulacoesData.conversao.category} />
                }
            </DataContainer>
            <DataContainer title="Conversão Perdida" bigvalue={tabulacoesData.conversao_perdida.big_value} obs="Do total de atendimentos"
                tempo_atendimento={tabulacoesData.conversao_perdida.tempo_atendimento}
                tempo_prim_resp={tabulacoesData.conversao_perdida.tempo_prim_resp}
                onClick={() => setCurrState({ ...currState, tabulacoesData: { ...tabulacoesData, active_tag: 'conversao_perdida' } })}
            >{tabulacoesData.conversao_perdida.category &&
                <SmallContainerPieChart data={tabulacoesData.conversao_perdida.category} />
                }
            </DataContainer>
            <DataContainer title="Suporte Pós Venda" bigvalue={tabulacoesData.suporte_pos_venda.big_value} obs="Do total de atendimentos"
                tempo_atendimento={tabulacoesData.suporte_pos_venda.tempo_atendimento}
                tempo_prim_resp={tabulacoesData.suporte_pos_venda.tempo_prim_resp}
                onClick={() => setCurrState({ ...currState, tabulacoesData: { ...tabulacoesData, active_tag: 'suporte_pos_venda' } })}
            >{tabulacoesData.suporte_pos_venda.category &&
                <SmallContainerPieChart data={tabulacoesData.suporte_pos_venda.category} />
                }
            </DataContainer>
            <DataContainer title="Não Clientes" bigvalue={tabulacoesData.nao_clientes.big_value} obs="Do total de atendimentos"
                tempo_atendimento={tabulacoesData.nao_clientes.tempo_atendimento}
                tempo_prim_resp={tabulacoesData.nao_clientes.tempo_prim_resp}
            >{tabulacoesData.nao_clientes.category &&
                <SmallContainerPieChart data={tabulacoesData.nao_clientes.category} />
                }
            </DataContainer>

        </div>

    )
}
export default TabulacaoHeader