import "./App.css";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Aside from "./components/Aside/Aside";

import Dashboard from "./components/Main/Dashboard";
import Funcionarios from "./components/Main/Funcionarios";
import Clientes from "./components/Main/Clientes";
import Main from "./components/Main/Main";
import Tabulacoes from "./components/Main/Tabulacoes";
import Login from "./components/Main/Login";
import Shopify from "./components/Main/Shopify";
import { useIsAuthenticated } from "react-auth-kit";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const today = new Date().setHours(23, 59, 59, 999)

  const [currState, setCurrState] = useState({
    clienteID: '0', //Todos no filtro
    funcID: '0', //Todos no filtro
    DataInicio: oneWeekAgo,
    DataFim: today,
  });

  const AuthElement = (Component) => {
    return isAuthenticated() ? Component : <Login currState={currState} setCurrState={setCurrState} />;
  };

  return (
    <React.StrictMode>
      <Aside />
      <Routes>
        <Route element={AuthElement(<Dashboard currState={currState} setCurrState={setCurrState} />)} path="/" exact />
        <Route element={AuthElement(<Dashboard currState={currState} setCurrState={setCurrState} />)} path="*" exact />
        <Route
          element={AuthElement(
            <Main currState={currState} setCurrState={setCurrState} title="Tabulações">
              <Tabulacoes currState={currState} setCurrState={setCurrState} />
            </Main>
          )}
          path="/tabulacoes"
        />
        <Route
          element={AuthElement(
            <Main currState={currState} setCurrState={setCurrState} title="Shopify">
              <Shopify currState={currState} setCurrState={setCurrState} />
            </Main>
          )}
          path="/shopify"
        />
        <Route
          element={AuthElement(
            <Main currState={currState} setCurrState={setCurrState} title="Funcionários">
              <Funcionarios currState={currState} setCurrState={setCurrState} />
            </Main>
          )}
          path="/funcionarios"
        />
        <Route
          element={AuthElement(
            <Main currState={currState} setCurrState={setCurrState} title="Clientes">
              <Clientes currState={currState} setCurrState={setCurrState} />
            </Main>
          )}
          path="/clientes"
        />
      </Routes>
    </React.StrictMode>
  );
}

export default App;
