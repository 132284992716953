import React from "react";
import SankeyChart from "../Charts/SankeyChart";
import ScatterPlotTimeline from "../Charts/ScatterPlotTimeline";

const ShopifyContainer = props => {
    const { shopifyData } = props
    const activeTag = 'pedidos_pagos'

    const tagNameByValue = {
        'pedidos_pagos': 'Pedidos Pagos',
        'tabulacoes': 'Tabulações',
        'atendimentos': 'Atendimentos'
    }
    return (

        <div className="tabulacao-container">
            <div className="tabulacao-tags wct">
                <div className="data-title">{tagNameByValue[activeTag]}</div>
                {shopifyData[activeTag] && shopifyData[activeTag].scatter &&
                    <div className="tabulacao-tags-tempos">
                        <ScatterPlotTimeline data={shopifyData[activeTag].scatter} />
                    </div>
                }
            </div>
            <div className="tabulacao-tempos wct">
                <div className="data-title">Funil de Conversão</div>
                {shopifyData[activeTag] && shopifyData[activeTag].sankey &&
                    <div className="tabulacao-tags-tempos">
                        <SankeyChart data={shopifyData[activeTag].sankey} />
                    </div>
                }
            </div>
        </div>
    )
}
export default ShopifyContainer