import React from "react";
import { ResponsiveScatterPlot } from '@nivo/scatterplot'

const ScatterPlotTimeline = props => {

    const mapedList = props.data ? props.data.map(
        scatterGroup => ({
            ...scatterGroup,
            'data': scatterGroup.data.map(item => ({ 'x': new Date(item.x), 'y': item.y, 'color': scatterGroup.color }))
        })
    ) : []
    console.log(mapedList)

    return (
        <ResponsiveScatterPlot
            data={mapedList}
            margin={{ top: 40, right: 40, bottom: 40, left: 80 }}
            colors={(data) => data.serieId == 'Conversão BeeFX' ? "hsla(147, 100%, 69%, 1)" : data.serieId == 'Vendas' ? "hsla(47, 100%, 69%, 1)" : "hsl(0, 1%, 80%)"}
            xScale={{
                type: 'time',
                format: '%d-%m-%Y',
                // precision: 'day'
            }}
            xFormat="time:%d-%m-%Y@%H:%M"
            yScale={{ type: 'linear', min: 0, max: 'auto' }}
            yFormat=">-.2f"
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                format: '%d/%m',
                tickValues: 'every 5 days'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Valor (R$)',
                legendPosition: 'middle',
                legendOffset: -60
            }}
            isInteractive={false}
            legends={[
                {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemWidth: 120,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default ScatterPlotTimeline