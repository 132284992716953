import React from "react";
import { useState, useRef, useEffect } from "react";
import '../Dashboard/Dashboard.css'
import HeaderContainer from "../Dashboard/HeaderContainers";
import GraphsContainer from "../Dashboard/GraphsContainer";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../../assets/logo.webp'
import DashPieChartWithLabels from "../Dashboard/charts/DashPieChartWithLabels";
import DashTimeline from "../Dashboard/charts/DashTimeline";
import LeftControls from "../Dashboard/LeftControls";


const Dashboard = props => {

    const { currState, setCurrState } = props
    const { relatorioData, DataInicio, DataFim, filterOptions } = currState
    const ref = useRef()
    const [relatorioGerado, setRelatorioGerado] = useState({
        nomeCliente: 'Selecione um cliente',
        dataInicio: '01/01/2000',
        dataFim: '31/01/2000',
    })


    useEffect(() => {
        if (!ref.firstRunDone && !filterOptions) fetchfilterOptions()
        ref.firstRunDone = true
    }, []);


    const fetchRelatorio = async () => {
        if (currState.clienteID == 0) return (window.alert('Selecione um cliente da BeeFX para gerar o relatório'))
        if (ref.fetchOn) return (window.alert('Geração de Relatório em Andamento. Aguarde...'))
        try {
            ref.fetchOn = true
            let res = await fetch(`/api/relatorios/from=${new Date(DataInicio).toISOString()}/to=${new Date(DataFim).toISOString()}/adm_id=${currState.clienteID}`)
            let data = await res.json()
            setCurrState({ ...currState, relatorioData: { ...data.db } })
            setRelatorioGerado({
                nomeCliente: getClienteName(),
                dataInicio: formatDate(new Date(DataInicio)),
                dataInicioISO: new Date(DataInicio).toISOString(),
                dataFim: formatDate(new Date(DataFim)),
                dataFimISO: new Date(DataFim).toISOString()
            })
            ref.fetchOn = false
        } catch (e) {
            window.alert(e)
        }
    }
    const fetchfilterOptions = () => {
        return new Promise(resolve => {
            if (filterOptions) resolve()
            try {
                fetch('/api/get/filterOptions')
                    .then(res => res.json())
                    .then(data => {
                        setCurrState({ ...currState, filterOptions: { ...data.db } })
                        resolve()
                    })
            } catch (e) {
                window.alert(e)
            }
        })

    }
    function formatDate(dt) {
        const day = String(dt.getDate()).padStart(2, '0');     // Get the day and pad with leading zero if needed
        const month = String(dt.getMonth() + 1).padStart(2, '0'); // Get the month (add 1 because months are zero-based) and pad with leading zero if needed
        const year = dt.getFullYear();                         // Get the year

        return `${day}/${month}/${year}`;
    }
    function getClienteName() {
        if (!filterOptions) return 'Nome do Cliente'
        let admName = filterOptions.admList.filter(op => op.id == currState.clienteID)
        return admName[0].nome
    }

    const captureA4PaperContent = () => {
        const container = document.getElementById('a4-container'); // Replace with your container's ID or ref
        html2canvas(container, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1.0);
            console.log(imgData)
            createPDF(imgData);
        });
    };
    const exportDataToExcel = async () => {
        const response = await fetch(`/download/excel?adm_id=${currState.clienteID}&data_inicio=${relatorioGerado.dataInicioISO}&data_fim=${relatorioGerado.dataFimISO}`);
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url
        a.download = `${relatorioGerado.nomeCliente}_${relatorioGerado.dataInicio}_${relatorioGerado.dataFim}.xlsx`; // Set the downloaded file name

        // Trigger a click event on the link to start the download
        const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(event);
    }

    const createPDF = (imgData) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
        pdf.save(`${relatorioGerado.nomeCliente}_${relatorioGerado.dataInicio}_${relatorioGerado.dataFim}.pdf`);
    };


    return (
        <main>
            <div className="pdf-generation-controls">
                <LeftControls currState={currState} setCurrState={setCurrState} clientes funcionarios update={fetchRelatorio} />
                {relatorioData &&
                    <div className="control-bottom-buttons">
                        <button className="export-action" onClick={captureA4PaperContent}>Exportar PDF</button>
                        <button className="export-action" onClick={exportDataToExcel}>Exportar Dados em Excel</button>
                    </div>
                }

            </div>
            <div id="a4-container">
                <div className="a4-header">
                    <div className="a4-logo">
                        <div className="a4-logo-circle">
                            <img src={logo} alt="No" />
                        </div>
                    </div>
                    <h2 className="a4-header1">Relatório de Atendimento BeeFX</h2>
                    <h3 className="a4-header2">{relatorioGerado.nomeCliente} | {relatorioGerado.dataInicio} - {relatorioGerado.dataFim}</h3>

                </div>
                {/* <svg id="wave" viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(243, 188, 62, 1)" offset="0%"></stop><stop stop-color="rgba(243, 188, 62, 1)" offset="100%"></stop></linearGradient></defs><path fill="url(#sw-gradient-0)" d="M0,60L10.9,63.3C21.8,67,44,73,65,73.3C87.3,73,109,67,131,55C152.7,43,175,27,196,30C218.2,33,240,57,262,68.3C283.6,80,305,80,327,71.7C349.1,63,371,47,393,48.3C414.5,50,436,70,458,73.3C480,77,502,63,524,53.3C545.5,43,567,37,589,28.3C610.9,20,633,10,655,18.3C676.4,27,698,53,720,68.3C741.8,83,764,87,785,88.3C807.3,90,829,90,851,88.3C872.7,87,895,83,916,78.3C938.2,73,960,67,982,58.3C1003.6,50,1025,40,1047,41.7C1069.1,43,1091,57,1113,65C1134.5,73,1156,77,1178,66.7C1200,57,1222,33,1244,28.3C1265.5,23,1287,37,1309,48.3C1330.9,60,1353,70,1375,71.7C1396.4,73,1418,67,1440,66.7C1461.8,67,1484,73,1505,68.3C1527.3,63,1549,47,1560,38.3L1570.9,30L1570.9,100L1560,100C1549.1,100,1527,100,1505,100C1483.6,100,1462,100,1440,100C1418.2,100,1396,100,1375,100C1352.7,100,1331,100,1309,100C1287.3,100,1265,100,1244,100C1221.8,100,1200,100,1178,100C1156.4,100,1135,100,1113,100C1090.9,100,1069,100,1047,100C1025.5,100,1004,100,982,100C960,100,938,100,916,100C894.5,100,873,100,851,100C829.1,100,807,100,785,100C763.6,100,742,100,720,100C698.2,100,676,100,655,100C632.7,100,611,100,589,100C567.3,100,545,100,524,100C501.8,100,480,100,458,100C436.4,100,415,100,393,100C370.9,100,349,100,327,100C305.5,100,284,100,262,100C240,100,218,100,196,100C174.5,100,153,100,131,100C109.1,100,87,100,65,100C43.6,100,22,100,11,100L0,100Z"></path></svg> */}
                <svg id="a4-header-wave" width="1920" height="240" viewBox="0 0 1920 240" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="240" fill="#f3bc3e" /><path d="M1920,240C1600.8333333333333,243.16666666666666,303.8333333333333,249.5,0,240C-303.8333333333333,230.5,63.666666666666664,201.33333333333334,97,183C130.33333333333334,164.66666666666666,166.33333333333334,127,200,130C233.66666666666666,133,265.6666666666667,187.5,299,201C332.3333333333333,214.5,366.3333333333333,214.5,400,211C433.6666666666667,207.5,466.6666666666667,181.66666666666666,501,180C535.3333333333334,178.33333333333334,572,197.83333333333334,606,201C640,204.16666666666666,671.5,210,705,199C738.5,188,773.6666666666666,129.83333333333334,807,135C840.3333333333334,140.16666666666666,871.3333333333334,221.66666666666666,905,230C938.6666666666666,238.33333333333334,974.6666666666666,199.83333333333334,1009,185C1043.3333333333333,170.16666666666666,1077.6666666666667,144,1111,141C1144.3333333333333,138,1175.5,157.5,1209,167C1242.5,176.5,1278.3333333333333,204.16666666666666,1312,198C1345.6666666666667,191.83333333333334,1377.5,125,1411,130C1444.5,135,1479,229.66666666666666,1513,228C1547,226.33333333333334,1581,131.66666666666666,1615,120C1649,108.33333333333333,1683.8333333333333,143.66666666666666,1717,158C1750.1666666666667,172.33333333333334,1781,195.5,1814,206C1847,216.5,1897.3333333333333,215.33333333333334,1915,221C1932.6666666666667,226.66666666666666,2239.1666666666665,236.83333333333334,1920,240C1600.8333333333333,243.16666666666666,303.8333333333333,249.5,0,240" fill="#f5f5f5" /></svg>

                <HeaderContainer relatorioData={relatorioData} />
                <div className="dash-graphs-grid">
                    <div className="histograma-dash wct">
                        <div className="data-title">Histórico de Atendimentos</div>
                        {relatorioData && relatorioData.atendimentos &&
                            <div className="histograma-dash-graph">
                                <DashTimeline data={relatorioData.atendimentos.scatter} />
                            </div>
                        }
                    </div>
                    {[{ id: 'conversao', name: 'Conversão' }, { id: 'conversao_perdida', name: 'Conversão Perdida' }, { id: 'suporte_pos_venda', name: 'Suporte Pós Venda' }].map(item => (
                        <div className="tabulacao-dash wct" id={item.id} key={item.id}>
                            <div className="data-title">{item.name}</div>
                            {relatorioData && relatorioData[item.id] &&
                                <div className="tabulacao-dash-graph">
                                    <DashPieChartWithLabels data={relatorioData[item.id]} />
                                </div>
                            }
                        </div>
                    ))}
                </div>


            </div>
        </main>
    )
}

export default Dashboard