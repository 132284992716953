import { useState, useRef, useEffect } from "react"
import '../Funcionarios/Funcionarios.css'
import TimeLineChart from "../Funcionarios/TimeLineChart"
import FuncPieChart from "../Funcionarios/FuncPieChart"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons/faChartColumn'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import FilterBar from "../FilterBar/FillterBar"


const Funcionarios = props => {
    const { currState, setCurrState } = props
    const { funcionarios, DataInicio, DataFim } = currState

    const firstRun = useRef()
    const funcionarioAnalise = useRef()
    const [timeLine, setTimelime] = useState([])

    useEffect(() => {
        if (!firstRun.done && !funcionarios) getFuncionarios()
        firstRun.done = true
    }, []);


    const handleChange = (e) => {
        let updatedFuncionarios = [...funcionarios]
        for (let func of [...updatedFuncionarios]) {
            if (func.id === Number(e.target.id)) {
                if (e.target.type == 'checkbox') {
                    func[e.target.name] = e.target.checked
                } else {
                    func[e.target.name] = e.target.value
                }
                func['saved'] = false
                break
            }
        }
        setCurrState({ ...currState, funcionarios: [...updatedFuncionarios] })


    }

    const getFuncionarios = async () => {
        try {
            let res = await fetch('/api/funcionarios')
            let data = await res.json()
            console.log(data.db)
            let funcionariosList = []
            if (data.db) {
                for (let func of data.db) {
                    funcionariosList.push({ ...func, saved: true })
                }
            }
            setCurrState({ ...currState, funcionarios: [...funcionariosList] })
        } catch (e) {
            console.log(e)
        }
    }

    const newFuncionario = () => {
        let confirm = window.confirm('Deseja criar um novo usuário?')
        if (confirm) {
            let fetchUrl = '/' + ['api', 'funcionarios', 'add'].join('/')
            fetch(fetchUrl)
                .then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        getFuncionarios()
                    } else {
                        window.alert(data.msg)
                    }
                })
                .catch(e => window.alert('Não foi possível acessar o servidor.'))
        }
    }

    const deleteFuncionario = idToDelete => {
        let confirm = window.confirm(`Deseja deletar o usuário de ID ${idToDelete}?`)
        if (confirm) {
            let fetchUrl = '/' + ['api', 'funcionarios', 'delete', String(idToDelete)].join('/')
            fetch(fetchUrl)
                .then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        let updatedFuncionarios = [...funcionarios]
                        updatedFuncionarios = updatedFuncionarios.filter(func => func.id != idToDelete)
                        setCurrState({ ...currState, funcionarios: [...updatedFuncionarios] })
                    } else {
                        window.alert(data.msg)
                    }
                })
                .catch(e => window.alert('Não foi possível acessar o servidor.'))
        }
    }

    const undoChanges = () => {
        getFuncionarios()
    }

    const saveChanges = async () => {
        let fetchUrl = '/' + ['api', 'funcionarios', 'update'].join('/')
        let options =
        {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                funcionarios.filter(func => !func.saved)
            )
        }
        fetch(fetchUrl, options)
            .then(res => res.json())
            .then(data => {
                console.log(`Url fetched: ${fetchUrl}`)
                console.log(data)
                if (data.ok) {
                    let updatedFuncionarios = [...funcionarios]
                    for (let func of updatedFuncionarios) {
                        func.saved = true
                    }
                    setCurrState({ ...currState, funcionarios: [...updatedFuncionarios] })
                } else {
                    window.alert(data.msg)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }


    const renderRows = () => {
        let sortedFuncionarios = [...funcionarios]
        sortedFuncionarios.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
        });


        return (

            sortedFuncionarios.map((func, idx) => (
                <tr key={func.id} className={func.id == funcionarioAnalise.id ? 'list-item selected-funcionario' : 'list-item'}>
                    <td>{func.id}</td>
                    <td><input type="text" id={func.id} name="nome" value={func.nome} onChange={handleChange} /></td>
                    <td><input type="text" id={func.id} name="login" value={func.login} onChange={handleChange} /></td>
                    <td><input type="text" id={func.id} name="senha" value={func.senha} onChange={handleChange} /></td>
                    <td><input type="text" id={func.id} name="adm_id_access_allowed" value={func.adm_id_access_allowed} onChange={handleChange} /></td>
                    <td><input type="checkbox" id={func.id} name="global_messages_allowed" checked={func.global_messages_allowed} onChange={handleChange} /></td>
                    <td><FontAwesomeIcon icon={faChartColumn} size="sm" onClick={() => getFuncionarioTimeline(func.id)} /></td>
                    <td><FontAwesomeIcon icon={faTrash} size="sm" onClick={() => deleteFuncionario(func.id)} /></td>
                </tr>
            ))
        )
    }

    const getFuncionarioTimeline = async (id) => {
        try {
            let res = await fetch(`/api/timeline/${id}/from=${new Date(DataInicio).toISOString()}/to=${new Date(DataFim).toISOString()}`)
            let data = await res.json()
            setTimelime(data.db)
            console.log(data)
            funcionarioAnalise.id = id
        } catch (e) {
            console.log(e)
        }
    }

    const unsavedFuncionarios = () => funcionarios ? funcionarios.filter(func => !func.saved).length > 0 : []

    const updateDateFilter = (db) => {
        getFuncionarioTimeline(13)
    }


    return (
        <>
            <div className="funcionarios-main">
                <div className="button-ct">
                    <button style={{ backgroundColor: '#fcf3a2' }} onClick={() => { newFuncionario() }}> Novo</button>
                    <button style={{ float: 'right' }} className={unsavedFuncionarios() ? 'save-btn' : 'save-btn off'} onClick={() => { saveChanges() }}> Salvar</button>
                    <button style={{ float: 'right' }} className={unsavedFuncionarios() ? 'undo-btn' : 'undo-btn off'} onClick={() => { undoChanges() }}> Desfazer</button>
                </div>
                <div className="funcionarios-list wct">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Login</th>
                                <th>Senha</th>
                                <th>Adms Permitidos</th>
                                <th>Mensagens Globais</th>
                                <th>Avaliar</th>
                                <th>Deletar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(funcionarios) && renderRows()}
                        </tbody>

                    </table>
                </div>
                {funcionarioAnalise.id &&
                    <>
                        <FilterBar currState={currState} setCurrState={setCurrState}
                            update={() => { if (funcionarioAnalise.id) getFuncionarioTimeline(funcionarioAnalise.id) }} />
                        <div className="funcionarios-graphs-grid">
                            <div className="indicador-timeline wct">
                                <TimeLineChart db={timeLine} />
                            </div>
                            <div className="indicador-pie flex-row">
                                <div className="pie-enquanto-logado wct flex-row">
                                    <div style={{ width: '40%' }}>
                                        <h2>Durante login ativo</h2>
                                        <p style={{ marginTop: '30px' }}>Status para o período selecionado.</p>
                                    </div>
                                    <div style={{ width: '60%' }}><FuncPieChart db={timeLine} removeOffline /></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Funcionarios