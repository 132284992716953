import React from "react";
import DataContainer from "./DataContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons/faMoneyBillTrendUp'
import SmallContainerPieChart from "../Charts/SmallContainerPieChart";

const HeaderContainer = props => {
    const { relatorioData } = props

    return (

        <div className="header-container">
            {relatorioData &&
                <>
                    <DataContainer title='Atendimentos' bigvalue={relatorioData.atendimentos.big_value} obs='Total de atendimentos registrados'>
                        <div className="header-container-icon"><FontAwesomeIcon icon={faHeadset} size='lg' style={{ color: "#ffb67a", }} /></div>
                    </DataContainer>
                    <DataContainer title='Valor Recuperado' bigvalue={relatorioData.valor_recuperado.big_value} obs=''>
                        <div style={{ marginLeft: 30 }} className="header-container-icon"><FontAwesomeIcon icon={faMoneyBillTrendUp} size='m' style={{ color: "#83cea7" }} /></div>
                    </DataContainer>
                    <DataContainer title="Conversões" bigvalue={relatorioData.conversoes.big_value} obs="Desconsiderando Suporte Pós Venda"
                    // onClick={() => setCurrState({ ...currState, shopifyData: { ...shopifyData, active_tag: 'tabulacoes' } })}
                    >{relatorioData.conversoes.category &&
                        <SmallContainerPieChart data={relatorioData.conversoes.category} />
                        }
                    </DataContainer>
                </>
            }


        </div>
    )
}

export default HeaderContainer