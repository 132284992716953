import React from "react";
import { ResponsiveScatterPlot } from '@nivo/scatterplot'

const DashTimeline = props => {

    const mapedList = props.data ? props.data.map(
        scatterGroup => ({
            ...scatterGroup,
            'data': scatterGroup.data.map(item => ({ 'y': new Date(item.y), 'x': item.x, 'color': scatterGroup.color }))
        })
    ) : []

    return (
        <ResponsiveScatterPlot
            data={mapedList}
            margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
            colors={(data) => "hsla(47, 100%, 69%, 1)"}
            yScale={{
                type: 'time',
                format: '%d-%m-%Y',

                // precision: 'day'
            }}
            yFormat="time:%d-%m-%Y@%H:%M"
            xScale={{ type: 'linear', min: 0, max: 'auto' }}
            xFormat=">-.1d"
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisLeft={{
                format: '%d/%m',
                tickValues: 'every 1 day'
            }}
            axisBottom={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: e => Math.floor(e) === e && e,
                legend: 'Atendimentos',
                legendPosition: 'middle',
                legendOffset: 36
            }}
            isInteractive={false}

        />
    )
}

export default DashTimeline