import React from 'react';
import DatePicker from "react-datepicker";
import './FilterBar.css'
import { useEffect, useRef } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons/faRotateRight'
const FilterBar = props => {
    //pros.update -> Reload function
    //props.funcionarios -> Add filtro funcionario
    //props.clientes -> Add fitro cliente

    const { currState, setCurrState } = props
    const { clienteID, funcID, DataInicio, DataFim, filterOptions } = currState

    const renderOptions = (type, opList) => {
        const selectedID = type == 'clientes' ? clienteID : funcID
        return (
            opList.map((op) =>
                <option key={op.id} id={op.id} value={op.id}
                    selected={selectedID == op.id}
                >{op.nome} </option>
            ))
    }

    return (
        <div className="filter-top-bar">
            <div className="reload-wrapper"
                onClick={
                    async function () {
                        document.querySelector('.reload-wrapper').classList.add("hide")
                        await props.update()
                        document.querySelector('.reload-wrapper').classList.remove("hide")
                    }
                }>
                <button className="reload-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path
                            d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
                        />
                    </svg>
                </button>
            </div>
            {/* <div className="updateicon"><FontAwesomeIcon icon={faRotateRight} color='gray' size='lg' onClick={() => props.update()} /></div> */}
            <FontAwesomeIcon icon={faFilter} color='gray' size='lg' />
            {filterOptions && props.clientes &&
                <div className="clientefilter">
                    <span>Cliente: </span>
                    <select name="Cliente" id="clienteSelect"
                        onChange={(e) => setCurrState({ ...currState, clienteID: e.target.value })}>
                        <option value="0" selected={clienteID == '0'}>Todos</option>
                        {renderOptions('clientes', filterOptions.admList)}
                    </select>
                </div>
            }
            {filterOptions && props.funcionarios &&
                <div className="funcfilter">
                    <span>Funcionário: </span>
                    <select name="Funcionários" id="func"
                        onChange={(e) => setCurrState({ ...currState, funcID: e.target.value })}>
                        <option value="0">Todos</option>
                        {renderOptions('funcionarios', filterOptions.funcList)}
                    </select>
                </div>
            }

            <div className="datefilter">
                <span style={{ marginRight: '5px' }}>Início: </span>
                <DatePicker locale={ptBR} selected={DataInicio}
                    onChange={(date) => setCurrState({ ...currState, DataInicio: date })}
                    dateFormat='dd/MM/yyyy' />

                <span style={{ margin: '0px 5px' }}>Fim: </span>
                <DatePicker locale={ptBR} selected={DataFim}
                    onChange={(date) => setCurrState({ ...currState, DataFim: date })}
                    dateFormat='dd/MM/yyyy' />

            </div>
        </div>
    )
}
export default FilterBar