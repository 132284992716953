import React, { useState, useEffect, useRef } from "react";
import FilterBar from "../FilterBar/FillterBar"
import ShopifyHeader from "../Shopify/ShopifyHeader";
import ShopifyContainer from "../Shopify/ShopifyContainer";
import '../Shopify/Shopify.css'



const Shopify = props => {

    const { currState, setCurrState } = props
    const { shopifyData, DataInicio, DataFim, filterOptions } = currState
    const firstRun = useRef()

    useEffect(() => {
        if (!firstRun.done && !shopifyData) fetchShopifyData()
        firstRun.done = true
    }, []);


    const fetchShopifyData = async () => {
        return new Promise(async (resolve) => {
            try {
                let filterData = filterOptions ? filterOptions : await fetchfilterOptions()
                let clienteID = currState.clienteID != 0 ? currState.clienteID : filterData.admList[0].id
                let res = await fetch(`/api/shopify/from=${new Date(DataInicio).toISOString()}/to=${new Date(DataFim).toISOString()}/adm_id=${clienteID}`)
                let data = await res.json()
                setCurrState({ ...currState, shopifyData: { ...data.db }, filterOptions: { ...filterData }, clienteID: filterData.admList[0].id })

            } catch (e) {
                window.alert(e)
            }
            resolve()
        })

    }
    const fetchfilterOptions = () => {
        return new Promise(resolve => {
            try {
                fetch('/api/get/filterOptions')
                    .then(res => res.json())
                    .then(data => resolve(data.db))
            } catch (e) {
                window.alert(e)
            }
        })

    }


    return (
        <>
            <FilterBar currState={currState} setCurrState={setCurrState}
                update={fetchShopifyData} clientes />
            {shopifyData &&
                <>
                    <ShopifyHeader currState={currState} setCurrState={setCurrState} shopifyData={shopifyData} />
                    <ShopifyContainer shopifyData={shopifyData} />
                </>
            }

        </>
    )

    // return (
    //     <p style={{ margin: 'auto' }}>Relatórios Shopify em desenvolvimento: 92%</p>
    // )
}

export default Shopify