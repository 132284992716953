import React from "react";
import DataContainer from "../Dashboard/DataContainer";
import SmallContainerPieChart from "../Charts/SmallContainerPieChart";

const ShopifyHeader = props => {
    const { currState, setCurrState, shopifyData } = props
    console.log(shopifyData)

    return (
        <div className="header-container shopify-header">
            <DataContainer title="Pedidos Pagos Convertidos" bigvalue={shopifyData.pedidos_pagos.big_value} obs="Do total de pedidos pagos convetidos pela BeeFX"
            // onClick={() => setCurrState({ ...currState, shopifyData: { ...shopifyData, active_tag: 'pedidos_pagos' } })}
            >{shopifyData.pedidos_pagos.category &&
                <SmallContainerPieChart data={shopifyData.pedidos_pagos.category} />
                }
            </DataContainer>

            <DataContainer title="Pedidos Processados" bigvalue={shopifyData.processados.big_value} obs="Do total de pedidos pagos"
            // onClick={() => setCurrState({ ...currState, shopifyData: { ...shopifyData, active_tag: 'tabulacoes' } })}
            >{shopifyData.processados.category &&
                <SmallContainerPieChart data={shopifyData.processados.category} />
                }
            </DataContainer>

            <DataContainer title="Atendimentos Shopify" bigvalue={shopifyData.atendimentos.big_value} obs="Dos pedidos atendidos pela BeeFX"
            // onClick={() => setCurrState({ ...currState, shopifyData: { ...shopifyData, active_tag: 'atendimentos' } })}
            >{shopifyData.atendimentos.category &&
                <SmallContainerPieChart data={shopifyData.atendimentos.category} />
                }
            </DataContainer>

        </div>

    )
}
export default ShopifyHeader