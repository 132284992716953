import { ResponsiveBar } from "@nivo/bar"
import { barColors } from "./barColors"


const TimeLineChart = props => {

    let mappedList = props.db ? props.db.map(obj => {
        return {
            Data: new Date(obj.datetime),
            ...(obj.ausente === false && { Online: 1 }),
            ...(obj.ausente === true && { Ausente: 1 }),
            color: barColors[obj.ausente ? 'ausente' : 'online']
        }
    }) : []

    return (
        <ResponsiveBar
            data={mappedList}
            keys={[
                'Online',
                'Ausente',
                "Offline"
            ]}
            indexBy="Data"
            margin={{ top: 0, right: 0, bottom: 0, left: 10 }}
            padding={0.15}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ datum: 'data.color' }}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
        // barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
        />
    )
}

export default TimeLineChart