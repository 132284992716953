import { useState, useRef, useEffect } from "react"
import '../Funcionarios/Funcionarios.css'
import TimeLineChart from "../Funcionarios/TimeLineChart"
import FuncPieChart from "../Funcionarios/FuncPieChart"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons/faChartColumn'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import FilterBar from "../FilterBar/FillterBar"
import { width } from "@fortawesome/free-solid-svg-icons/faFilter"


const Clientes = props => {
    const { currState, setCurrState } = props
    const { clientes } = currState

    const firstRun = useRef()

    useEffect(() => {
        if (!firstRun.done && !clientes) getClientes()
        firstRun.done = true
    }, []);


    const handleChange = (e) => {
        let updatedClientes = [...clientes]
        for (let cliente of [...updatedClientes]) {
            if (cliente.id === Number(e.target.id)) {
                cliente[e.target.name] = e.target.value
                cliente['saved'] = false
                break
            }
        }
        setCurrState({ ...currState, clientes: [...updatedClientes] })


    }

    const getClientes = async () => {
        try {
            let res = await fetch('/api/clientes')
            let data = await res.json()
            let clientesList = []
            if (!data.db) {
                setCurrState({ ...currState, clientes: [...clientesList] })
                return
            }
            for (let cliente of data.db) {
                clientesList.push({ ...cliente, saved: true })
            }
            setCurrState({ ...currState, clientes: [...clientesList] })
            console.log(clientesList)
        } catch (e) {
            console.log(e)
        }
    }

    const newCliente = () => {
        let confirm = window.confirm('Deseja criar um novo cliente?')
        if (confirm) {
            let fetchUrl = '/' + ['api', 'clientes', 'add'].join('/')
            fetch(fetchUrl)
                .then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        getClientes()
                    } else {
                        window.alert(data.msg)
                    }
                })
                .catch(e => window.alert(`Não foi possível acessar o servidor. Erro: ${e}`))
        }
    }

    const deleteCliente = idToDelete => {
        let confirm = window.confirm(`Deseja deletar o cliente de ID ${idToDelete}?`)
        if (confirm) {
            let fetchUrl = '/' + ['api', 'clientes', 'delete', String(idToDelete)].join('/')
            fetch(fetchUrl)
                .then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        let updatedClientes = [...clientes]
                        updatedClientes = updatedClientes.filter(func => func.id != idToDelete)
                        setCurrState({ ...currState, clientes: [...updatedClientes] })
                    } else {
                        window.alert(data.msg)
                    }
                })
                .catch(e => window.alert('Não foi possível acessar o servidor.'))
        }
    }

    const undoChanges = () => {
        getClientes()
    }

    const saveChanges = async () => {
        let fetchUrl = '/' + ['api', 'clientes', 'update'].join('/')
        let options =
        {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                clientes.filter(cliente => !cliente.saved)
            )
        }
        fetch(fetchUrl, options)
            .then(res => res.json())
            .then(data => {
                console.log(`Url fetched: ${fetchUrl}`)
                console.log(data)
                if (data.ok) {
                    let updatedClientes = [...clientes]
                    for (let cliente of updatedClientes) {
                        cliente.saved = true
                    }
                    setCurrState({ ...currState, clientes: [...updatedClientes] })
                } else {
                    window.alert(data.msg)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }


    const renderRows = () => {
        let sortedClientes = [...clientes]
        sortedClientes.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
        });


        return (

            sortedClientes.map((cliente, idx) => (
                <tr key={cliente.id} className='list-item'>
                    <td>{cliente.id}</td>
                    <td><input type="text" id={cliente.id} name="nome" value={cliente.nome} onChange={handleChange} /></td>
                    <td><input type="text" id={cliente.id} style={{ width: '340px' }} name="shopify_domain" value={cliente.shopify_domain} onChange={handleChange} /></td>
                    <td><input type="password" id={cliente.id} name="api_admin_secret" value={cliente.api_admin_secret} onChange={handleChange} /></td>
                    <td><input type="password" id={cliente.id} name="api_key" value={cliente.api_key} onChange={handleChange} /></td>
                    <td><FontAwesomeIcon icon={faTrash} size="sm" onClick={() => deleteCliente(cliente.id)} /></td>
                </tr>
            ))
        )
    }


    const unsavedClientes = () => clientes ? clientes.filter(cliente => !cliente.saved).length > 0 : []


    return (
        <>
            <div className="funcionarios-main">
                <div className="button-ct">
                    <button style={{ backgroundColor: '#fcf3a2' }} onClick={() => { newCliente() }}> Novo</button>
                    <button style={{ float: 'right' }} className={unsavedClientes() ? 'save-btn' : 'save-btn off'} onClick={() => { saveChanges() }}> Salvar</button>
                    <button style={{ float: 'right' }} className={unsavedClientes() ? 'undo-btn' : 'undo-btn off'} onClick={() => { undoChanges() }}> Desfazer</button>
                </div>
                <div className="funcionarios-list wct">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Domínio Shopify</th>
                                <th>API Admin Secret</th>
                                <th>API Key</th>
                                <th>Deletar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(clientes) && renderRows()}
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    )
}

export default Clientes