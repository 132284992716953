import React, { useState } from "react";
import { useSignIn } from "react-auth-kit";
import './Login.css'
import beefxLogo from '../../assets/beefx.webp'

const Login = props => {
    const [user, setUser] = useState({
        login: null,
        password: null,
        errorMessage: null
    })
    const signIn = useSignIn()

    const submitLogin = async () => {
        try {
            let res = await fetch('/api/dashboard/login',
                {
                    method: 'POST', headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        login: user.login,
                        password: user.password
                    })
                })
            let data = await res.json()
            if (data.ok) {
                signIn({
                    token: data.token,
                    expiresIn: 30,
                    tokenType: 'Bearer',
                    authState: { login: data.login }
                })

            } else {
                if (data.msg) setUser({ ...user, errorMessage: data.msg })
            }
            return

        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="fc">
            <div className="login-container">
                <img src={beefxLogo} alt="" srcset="" />
                <p>Usuário</p>
                <input onChange={e => setUser({ ...user, login: e.target.value })} />
                <p>Senha</p>
                <input type="password" onChange={e => setUser({ ...user, password: e.target.value })} />
                <p className="hide">Cliente</p>
                <span className="login-loading-circle hide"></span>
                <button className="login-btn" onClick={() => submitLogin()}>Login</button>
                <p className="login-error-msg">{user.errorMessage ? user.errorMessage : ''}</p>
            </div>
        </div>

    )
}

export default Login