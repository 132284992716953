import { ResponsivePie } from '@nivo/pie'
import { barColors } from './barColors';

const FuncPieChart = props => {

    const countStatus = {
        'online': 0,
        'ausente': 0,
        'offline': 0,
    }
    if (props.db) for (let item of props.db){countStatus[item.ausente ? 'ausente' : 'online'] += 1;}
    let total = countStatus['online'] + countStatus['ausente']

    const mainData = props.db ? [
        {
            "id": "Online",
            "label": "Online",
            "value": Math.round(countStatus['online']*100/total),
            "color": barColors['online']
        },
        {
            "id": "Ausente",
            "label": "Ausente",
            "value": Math.round(countStatus['ausente']*100/total),
            "color": barColors['ausente']
        }
    ] : []


    return (
        <ResponsivePie
            data={mainData}
            margin={{ top: 20, right: 0, bottom: 10, left: 10 }}
            innerRadius={0.6}
            activeOuterRadiusOffset={8}
            colors={{ datum: 'data.color' }}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={false}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default FuncPieChart